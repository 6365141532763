import React from "react";
import {
    View,
    Text,
    StyleSheet,
    Platform,
    I18nManager,
    Animated,
    TouchableOpacity,
    Alert,
    Button,
    ScrollView,
    TextInput,
    TouchableHighlight,
    Dimensions,
    FlatList,
    KeyboardAvoidingView
} from "react-native";

import Icon from 'react-native-vector-icons/Feather';
import { colors } from '../constants/colors';
import { fonts } from '../constants/fonts';

import Modal from 'modal-enhanced-react-native-web';

import { useDispatch, useSelector} from 'react-redux'
import {  useNavigation } from '@react-navigation/native'


class NotesModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      note: this.props.cartData.note
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.cartData.note !== this.props.cartData.note) {
      this.setState({
        note: nextProps.cartData.note
      });
    }
  }


  submitForm = () => {
    return (
      this.props.addOrderNote(this.state.note),
      this.props.toggleNotesModal()
    )
  }

  renderNotesModal = (isVisible, toggleNotesModal, cartData) => {
    return (
      <Modal
        animationIn={global.windowWidth > 768 ? "fadeIn" : "slideInUp"}
        animationOut={global.windowWidth > 768 ? "fadeOut" : "slideOutDown"}
        isVisible={this.props.isVisible}
        style={global.windowWidth > 768 ? styles.containerlWeb : styles.container}
        deviceHeight={Dimensions.get('window').height}
        deviceWidth={Dimensions.get('window').width}
      >
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : "height"}
        >
        <View style={[global.windowWidth > 768 ? styles.modalWeb : styles.modal]}>

              <View style={[styles.header, { paddingHorizontal: 30, paddingTop: 30,}]}>
                <Text style={styles.heading} adjustsFontSizeToFit allowFontScaling>Order Notes</Text>
                  <TouchableOpacity
                    onPress={() => toggleNotesModal()}
                    style={styles.headerClose}>
                    <Icon name="x" size={25} color={colors.textSecondary}/>
                </TouchableOpacity>
              </View>

              <View style={{ paddingTop: 10, paddingHorizontal: 30}}>

              <TextInput
                value={this.state.note}
                autoCapitalize="sentences"
                maxHeight={60}
                onChangeText={(note) => this.setState({ note })}
                placeholder="Add some notes about your order here. Please don't include allergen information here without contacting the restaurant first."
                style={{ paddingVertical: 30, paddingHorizontal: 20, fontFamily: fonts.regular, borderWidth: 1, borderColor: colors.border }}
                                returnKeyType="next"
              />

              <TouchableOpacity
                onPress={() => this.submitForm()}
                disabled={this.state.note ? false : true }
                style={this.state.note ? styles.orderButton : styles.orderButtonDisabled}
              >
                <Text style={styles.buttonText} adjustsFontSizeToFit allowFontScaling>Add Notes</Text>
              </TouchableOpacity>
              </View>
          </View>
          </KeyboardAvoidingView>
        </Modal>
    )
  }

  render() {
    return (
      <View>{this.renderNotesModal(this.props.isVisible, this.props.toggleNotesModal, this.props.cartData, this.props.addOrderNote)}</View>
    )
  }
}

export default NotesModal;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 0,
    justifyContent: "flex-end"
  },
  containerWeb: {
    flex: 1,
    margin: 0,
    alignContent: "center",
  },
  text: {
    color: colors.text,
    fontFamily: fonts.regular
  },
  modal: {
    minHeight: 290,
    backgroundColor:colors.white,
    width: Dimensions.get('window').width,
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
  },
  modalWeb: {
    minHeight: 290,
    backgroundColor:colors.white,
    minWidth: 600,
    alignSelf: "center",
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
    borderBottomRightRadius: 15,
    borderBottomLeftRadius: 15,
  },
  header: {
    flexDirection: "row",
    marginBottom: 10,
  },
  headerClose: {
    flex: 0.5,
    alignItems: "flex-end",
  },
  heading: {
    flex: 4,
    color: colors.text,
    fontSize: 22,
    fontFamily: fonts.bold,
  },
  orderButton: {
    backgroundColor: colors.secondary,
    paddingVertical: 20,
    paddingHorizontal: 30,
    borderRadius: 40,
    marginTop: 20,
  },
  orderButtonDisabled: {
    backgroundColor: colors.secondary,
    opacity: 0.8,
    paddingVertical: 20,
    paddingHorizontal: 30,
    borderRadius: 40,
    marginTop: 20,
  },
  buttonText: {
    fontFamily: fonts.bold,
    fontSize: 15,
    textAlign: "center",
    color: colors.primary
  },
  items: {
    flex: 5,
    paddingVertical: 20,
  },
  receiptDetails: {
    flex: 0.5,
    borderTopWidth: 1,
    borderTopColor: colors.border
  },
  orderDetails: {
    flex: 2,
    borderTopWidth: 1,
    borderTopColor: colors.border
  },
  orderType: {
    flexDirection: "row",
    paddingVertical: 15,
    borderBottomWidth: 1,
    borderBottomColor: colors.border
  },
  orderTypeIcon: {
    marginTop: 5,
    marginRight: 15,
  },
  orderTypeOpen: {
    marginTop: 5,
    marginRight: 15,
    alignContent: "flex-end"
  },
  orderTypeText: {
  },
  receiptDetails: {
    flex: 1.5,
  },
  subHeading: {
    fontSize: 12,
    letterSpacing: 2,
    marginVertical: 10,
    textTransform: "uppercase",
    fontWeight: "700"
  },
  findPostcodeInput: {
    flex: 4,
    paddingVertical: 20,
    paddingHorizontal: 20,
    borderWidth: 1,
    borderColor: colors.border
  },
  findPostcodeButton: {
    flex: 1,
    paddingVertical: 20,
    paddingHorizontal: 20,
    backgroundColor: colors.primary,
  }
});
