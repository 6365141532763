
import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';

import * as firebase from 'firebase';
import 'firebase/firestore';

// Set Department Data

const setDepartmentData = (departmentData) => {
  return {
    type: "SET_DEPARTMENT_DATA",
    value: departmentData,
  }
}

const watchDepartmentData = (departmentData, company) => {
  return function(dispatch) {
    backendFirebase.firestore()
        .collection("/products/" + global.companyId + "/departments")
        .where("branch", "in", [global.branchId, false])
        .where("isOnline", "==", true)
        .onSnapshot(querySnapshot => {
          const data = querySnapshot.docs.map(doc => {
            const id = doc.id
            const data = doc.data()

            return Object.assign( {id, ...data} )
          });
          var actionSetProductData = setDepartmentData(data);
          dispatch(actionSetProductData);
        }), function(error) {
          console.log("WatchDepartmentData Error: " + error);
        };
  }
};

// Set Category Data

const setCategoryData = (categoryData) => {
  return {
    type: "SET_CATEGORY_DATA",
    value: categoryData,
  }
}

const watchCategoryData = (categoryData, company) => {

  return function(dispatch) {
    backendFirebase.firestore()
        .collection("/products/" + global.companyId + "/categories")
        .where("branch", "in", [global.branchId, false])
        .where("isOnline", "==", true)
        .orderBy("order", "asc")
        .onSnapshot(querySnapshot => {
          const data = querySnapshot.docs.map(doc => {
            const id = doc.id
            const data = doc.data()
            return Object.assign({id, ...data})
          });
          var actionSetCategoryData = setCategoryData(data);
          dispatch(actionSetCategoryData);
        }), function(error) {
          console.log("WatchDepartmentData Error: " + error);
        };
  }
};

// Set Item Data

const setItemData = (itemData) => {
  return {
    type: "SET_ITEM_DATA",
    value: itemData,
  }
}

const watchItemData = (userData) => {
  return function(dispatch) {
    backendFirebase.firestore()
        .collection("/products/" + global.companyId + "/items")
        .where("branch", "in", [global.branchId, false])
        .orderBy("name", "asc")
        .onSnapshot(querySnapshot => {

          const data = querySnapshot.docs.map(doc => {
            const id = doc.id
            const data = doc.data()

            return Object.assign({id, ...data})
          });
          var actionSetItemData = setItemData(data);
          dispatch(actionSetItemData);
        }), function(error) {
          console.log("WatchItemData Error: " + error);
        };
  }
};


// Set Item Variant Data

const setItemVariantData = (itemVariantData) => {
  return {
    type: "SET_ITEM_VARIANT_DATA",
    value: itemVariantData,
  }
}

const watchItemVariantData = (userData) => {
  return function(dispatch) {
    backendFirebase.firestore()
        .collection("/products/" + global.companyId + "/itemsVariants")
        .orderBy("name", "asc")
        .onSnapshot(querySnapshot => {

          const data = querySnapshot.docs.map(doc => {
            const id = doc.id
            const data = doc.data()

            return Object.assign({id, ...data})
          });
          var actionSetItemVariantData = setItemVariantData(data);
          dispatch(actionSetItemVariantData);
        }), function(error) {
          console.log("WatchItemVariantData Error: " + error);
        };
  }
};

// Set Item Variant Data

const setModifierData = (modifierData) => {
  return {
    type: "SET_MODIFIER_DATA",
    value: modifierData,
  }
}

const watchModifierData = (userData) => {
  return function(dispatch) {
    backendFirebase.firestore()
        .collection("/products/" + global.companyId + "/modifiers")
        .where("isOnline", "==", true)
        .orderBy("name", "asc")
        .onSnapshot(querySnapshot => {

          const data = querySnapshot.docs.map(doc => {
            const id = doc.id
            const data = doc.data()

            return Object.assign({id, ...data})
          });
          var actionSetModifierData = setModifierData(data);
          dispatch(actionSetModifierData);
        }), function(error) {
          console.log("WatchModifierData Error: " + error);
        };
  }
};

const setModifierItemData = (modifierItemData) => {
  return {
    type: "SET_MODIFIER_ITEM_DATA",
    value: modifierItemData,
  }
}

const watchModifierItemData = (modifierData) => {
  return function(dispatch) {
    backendFirebase.firestore()
        .collection("/products/" + global.companyId + "/modifierItems")
        .orderBy("name", "asc")
        .onSnapshot(querySnapshot => {

          const data = querySnapshot.docs.map(doc => {
            const id = doc.id
            const data = doc.data()

            return Object.assign({id, ...data})
          });

          var actionSetModifierItemData = setModifierItemData(data);
          dispatch(actionSetModifierItemData);
        }), function(error) {
          console.log("WatchModifierItemData Error: " + error);
        };
  }
};

export {setItemData, watchItemData, setItemVariantData, watchItemVariantData, setDepartmentData, watchDepartmentData, setCategoryData, watchCategoryData, setModifierData, watchModifierData, setModifierItemData, watchModifierItemData};
