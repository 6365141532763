import React, { useState } from "react";
import {
    View,
    Text,
    StyleSheet,
    Platform,
    I18nManager,
    Animated,
    TouchableOpacity,
    Alert,
    Button,
    ScrollView,
    ActivityIndicator,
    TextInput,
    TouchableHighlight,
    Dimensions,
    FlatList,
    KeyboardAvoidingView
} from "react-native";

import Icon from 'react-native-vector-icons/Feather';
import { colors } from '../constants/colors';

import Modal from 'modal-enhanced-react-native-web';

import axios from 'axios'

import { useDispatch, useSelector} from 'react-redux'
import {  useNavigation } from '@react-navigation/native'


function DiscountButton({ setError, error, code, addOrderDiscount, toggleDiscountModal }) {

  const branch = useSelector(state => state.branchesData.selectedBranch);
  const [isLoading, setLoading] = useState(false);


  // When customer has entered data, it will ask server is there's a discount code if so it will return the data
  const onPress = async (discountCode) => {

    setLoading(true)
    await axios.post("https://splinter.cloud.yuze.uk/find-discount",
    {
      code: code,
      branch: branch.id,
      company: global.companyId
    })
    .then(response => response.data)
    .then(response => {

      if (response.type == "error") {
        setError(response.message)

      } else if (response.type == "success") {
        addOrderDiscount(response.discount)
        toggleDiscountModal()
      }

      setLoading(false)

    })
    .catch(error => {
      setError(error.name + ": " + error.message)
      setLoading(false)
    });
  }

  return (
    <TouchableOpacity
      onPress={onPress}
      disabled={code ? false : true }
      style={code ? styles.orderButton : styles.orderButtonDisabled}
    >
    {isLoading ?
        <ActivityIndicator size="small" color={colors.text} />
      : <Text style={styles.buttonText} adjustsFontSizeToFit allowFontScaling>Add Promo Code</Text>
    }
    </TouchableOpacity>
  )
}

class DiscountModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      code: this.props.cartData.discount.code,
      errorMessage: null,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.cartData.discount.code !== this.props.cartData.discount.code) {
      this.setState({
        note: nextProps.cartData.discount.code,
        errorMessage: null,
      });
    }
  }

  setError = (error) => {
    this.setState({
      errorMessage: error,
    });
  }

  renderDiscountModal = (isVisible, toggleDiscountModal, cartData, addOrderDiscount) => {
    return (
      <Modal
        animationIn={global.windowWidth > 768 ? "fadeIn" : "slideInUp"}
        animationOut={global.windowWidth > 768 ? "fadeOut" : "slideOutDown"}
        isVisible={isVisible}
        style={global.windowWidth > 768 ? styles.containerlWeb : styles.container}
        deviceHeight={Dimensions.get('window').height}
        deviceWidth={Dimensions.get('window').width}
      >
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : "height"}
        >
        <View style={[global.windowWidth > 768 ? styles.modalWeb : styles.modal]}>
              <View style={[styles.header, { paddingHorizontal: 30, paddingTop: 30,}]}>
                <Text style={styles.heading} adjustsFontSizeToFit allowFontScaling>Promo Code</Text>
                  <TouchableOpacity
                    onPress={() => toggleDiscountModal()}
                    style={styles.headerClose}>
                    <Icon name="x" size={25} color={colors.textSecondary}/>
                </TouchableOpacity>
              </View>

              <View style={{ paddingTop: 10, paddingHorizontal: 30}}>

              <TextInput
                value={this.state.code}
                autoCapitalize="characters"
                onChangeText={(code) => this.setState({ code, errorMessage: null })}
                placeholderTextColor = {colors.textSecondary}
                placeholder="Enter your promotional code here"
                style={{ padding: 20, borderWidth: 1, paddingVertical: 30, borderColor: colors.border }}
                returnKeyType="next"
              />

              <Text style={{ marginTop: 5, marginBottom: 10, color: colors.red}} adjustsFontSizeToFit allowFontScaling>{this.state.errorMessage}</Text>

              <DiscountButton
                setError={this.setError}
                code={this.state.code}
                errorMessage={this.state.errorMessage}
                addOrderDiscount={this.props.addOrderDiscount}
                toggleDiscountModal={this.props.toggleDiscountModal}
              />
              </View>
          </View>
          </KeyboardAvoidingView>
        </Modal>
    )
  }

  render() {
    return (
      <View>{this.renderDiscountModal(this.props.isVisible, this.props.toggleDiscountModal, this.props.cartData, this.props.addOrderDiscount)}</View>
    )
  }
}

export default DiscountModal;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 0,
    justifyContent: "flex-end"
  },
  containerWeb: {
    flex: 1,
    margin: 0,
    alignContent: "center",
  },
  text: {
    color: colors.text,
  },
  modal: {
    minHeight: 290,
    backgroundColor:colors.white,
    width: Dimensions.get('window').width,
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
  },
  modalWeb: {
    minHeight: 290,
    backgroundColor:colors.white,
    minWidth: 600,
        alignSelf: "center",
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
    borderBottomRightRadius: 15,
    borderBottomLeftRadius: 15,
  },
  header: {
    flexDirection: "row",
    marginBottom: 10,
  },
  headerClose: {
    flex: 0.5,
    alignItems: "flex-end",
  },
  heading: {
    flex: 4,
    color: colors.text,
    fontSize: 22,
    fontWeight: "700",
  },
  orderButton: {
    backgroundColor: colors.secondary,
    paddingVertical: 20,
    paddingHorizontal: 30,
    borderRadius: 40,
    marginTop: 20,
  },
  orderButtonDisabled: {
    backgroundColor: colors.secondary,
    opacity: 0.8,
    paddingVertical: 20,
    paddingHorizontal: 30,
    borderRadius: 40,
    marginTop: 20,
  },
  buttonText: {
    fontWeight: "700",
    fontSize: 15,
    textAlign: "center",
    color: colors.primary
  },
  items: {
    flex: 5,
    paddingVertical: 20,
  },
  receiptDetails: {
    flex: 0.5,
    borderTopWidth: 1,
    borderTopColor: colors.border
  },
  orderDetails: {
    flex: 2,
    borderTopWidth: 1,
    borderTopColor: colors.border
  },
  orderType: {
    flexDirection: "row",
    paddingVertical: 15,
    borderBottomWidth: 1,
    borderBottomColor: colors.border
  },
  orderTypeIcon: {
    marginTop: 5,
    marginRight: 15,
  },
  orderTypeOpen: {
    marginTop: 5,
    marginRight: 15,
    alignContent: "flex-end"
  },
  orderTypeText: {
  },
  receiptDetails: {
    flex: 1.5,
  },
  subHeading: {
    fontSize: 12,
    letterSpacing: 2,
    marginVertical: 10,
    textTransform: "uppercase",
    fontWeight: "700"
  },
  findPostcodeInput: {
    flex: 4,
    paddingVertical: 20,
    paddingHorizontal: 20,
    borderWidth: 1,
    borderColor: colors.border
  },
  findPostcodeButton: {
    flex: 1,
    paddingVertical: 20,
    paddingHorizontal: 20,
    backgroundColor: colors.primary,
  }
});
