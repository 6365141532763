import React from "react";
import {
    View,
    Text,
    StyleSheet,
    Platform,
    I18nManager,
    Animated,
    TouchableOpacity,
    Alert,
    Button,
    ScrollView,
    TextInput,
    TouchableHighlight,
    Dimensions,
    FlatList,
    ActivityIndicator,
    KeyboardAvoidingView
} from "react-native";

import Icon from 'react-native-vector-icons/Feather';
import { colors } from '../constants/colors';

import Modal from 'modal-enhanced-react-native-web';

import { useDispatch, useSelector} from 'react-redux'
import {  useNavigation } from '@react-navigation/native'

import AddressesScreen from './../screens/AddressesScreen'
import CustomerSelectAddress from './CustomerSelectAddress'

import { getAddresses } from './../api/addresses'

import { fonts } from '../constants/fonts'


function AddAddressButton({ line1, line2, town, county, postcode, location, toggleAddressModal, selectedAddress }) {

  const dispatch = useDispatch()
  const navigation = useNavigation()

  const address = {
    line1: line1,
    line2: line2,
    town: town,
    county: county,
    postcode: postcode,
    location: location
  }

  // Need to add location here as well

  const addCustomerAddress = (address) => {
    return {
      type: "ADD_CUSTOMER_ADDRESS",
      payload: address,
    }
  }

  const onPress = () => {
    return (
      dispatch(addCustomerAddress(address)),
      toggleAddressModal()
    )
  }

  if (selectedAddress != null && line1 != "") {
    return (
      <TouchableHighlight
        onPress={onPress}
        style={styles.orderButton}
      >
        <Text style={styles.buttonText} adjustsFontSizeToFit allowFontScaling>Add Delivery Address</Text>
      </TouchableHighlight>
    )
  } else {
    return null
  }
}

function AddSavedAddress({ userData, checkPostcode, toggleAddressModal, addError }) {

  const dispatch = useDispatch()

  // Need to add location here as well

  const addCustomerAddress = (address) => {
    return {
      type: "ADD_CUSTOMER_ADDRESS",
      payload: address,
    }
  }

  const onPress = () => {
    // Needs to verify delivery location and then add to state.rr
    if(checkPostcode(userData.usersData[0].address.postcode) == false) {
      addError("Sorry, we currently don't deliver to that area.")
    } else {
      dispatch(addCustomerAddress(userData.usersData[0].address)),
      toggleAddressModal()
    }
  }

  return (

    <TouchableOpacity
      onPress={onPress}
      style={styles.savedAddress}>

      <View style={styles.savedAddressText} adjustsFontSizeToFit allowFontScaling>
        <Text style={{ fontSize: 15, fontFamily: fonts.bold}} adjustsFontSizeToFit allowFontScaling>{userData.usersData[0].address.line1}</Text>
        <Text style={{color: colors.textSecondary}} adjustsFontSizeToFit allowFontScaling>{userData.usersData[0].address.line2 ? userData.usersData[0].address.line2 + ", ": null}{userData.usersData[0].address.town}, {userData.usersData[0].address.postcode}</Text>
      </View>
      <Icon name="ios-arrow-forward" style={styles.savedAddressOpen} size={25} color={colors.border}/>

    </TouchableOpacity>

  )
}

class AddressModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      postcode: this.props.cartData.customer.postcode,
      errorMessage: null,
      foundAddresses: [],
      selectedAddress: null,
      isFindAddressVisible: false,
      addressLoading: false,
      location: this.props.cartData.customer.location,
      line1: this.props.cartData.customer.line1,
      line2: this.props.cartData.customer.line2,
      town: this.props.cartData.customer.town,
      county: this.props.cartData.customer.county,
    }
  }

componentWillReceiveProps(nextProps) {
  if (nextProps.cartData !== this.props.cartData) {
    this.setState({
      foundAddresses: [],
      selectedAddress: null,
      isFindAddressVisible: false,
      postcode: nextProps.cartData.customer.postcode,
      location: nextProps.cartData.customer.location,
      line1: nextProps.cartData.customer.line1,
      line2: nextProps.cartData.customer.line2,
      town: nextProps.cartData.customer.town,
      county: nextProps.cartData.customer.county,
    });
  }
}

  selectAddress = (address) => {

    if (typeof address == "undefined") {
      return (
        this.setState({
          selectedAddress: null,
          line1: null,
          line2: null,
          town: null,
          county: null,
        })
      )
    } else {
      return (
        this.setState({
          selectedAddress: address,
          line1: address.line_1,
          line2: address.line_2,
          town: address.town_or_city,
          county: address.county,
        })
      )
    }
  }

  // Checks to see whether business delivers to user postcode
  checkPostcode = (postCode) => {
    let postcode

    if (postCode != "") {
      postcode = this.state.postcode.toUpperCase()
    } else {
      postcode = postCode.toUpperCase()
    }
    // Removes spaces from auto correct
    postcode = postcode.replace(/\s+/g, '')

    // Postcode Validation
    const postcodeReg = new RegExp(/^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i)

    if (postcodeReg.test(postcode) === false) {
      this.setState({errorMessage: "Please enter a valid postcode"})
      return false
    } else {
      let locations = this.props.deliveriesData
      let location = []

      // Finds if user postcode can get food!
      locations.map(l => {
        l.codes.map(code => {
          if(postcode.replace(/(\S*)\s*(\d)/, "$1 $2").includes(code)) {
            location.push(l)
          }
        })
      })

      if (location.length > 0) {
        return location
      } else {
        this.setState({errorMessage: "Sorry, we currently don't deliver to that area."})
        return false
      }
    }
  }

  // Find Postcode finds the users addresses by returning an array from the entered postcode. Does the postcode check first.
  findPostcode = () => {

    this.setState({ addressLoading: true })

    const checkPostcode = this.checkPostcode()

    if (checkPostcode == false) {
        return (
          this.setState({errorMessage: "Sorry, we currently don't deliver to that area.", addressLoading: false })
        )
      } else {

      // Add location charges onto state
      const location = checkPostcode
      this.setState({ location: location })

      // This gets the rest of the address to display.
      getAddresses(this.state.postcode)
      .then(data =>  {
        if (Array.isArray(data)) {
          this.setState({ foundAddresses: data, isFindAddressVisible: true, addressLoading: false})
        } else {
          this.setState({line1: "", line2: "", town: "", county: "", foundAddresses: [], isFindAddressVisible: false, addressLoading: false})
        }

      })
      .catch(err => {
        this.setState({line1: "", line2: "", town: "", county: "", foundAddresses: [], errorMessage: "Error:" + err, isFindAddressVisible: false, addressLoading: false})
      });
    }

  }

  resetError = () => {
    this.setState({errorMessage: null})
  }

  addError = (error) => {
    this.setState({errorMessage: error})
  }



  //<View>
    //<Text style={{fontSize: 14, fontWeight: "600", marginTop: 20, marginBottom: 10}}>Saved Address</Text>

    //<AddSavedAddress
    //  addError={this.addError}
    //  toggleAddressModal={() => toggleAddressModal()}
    //  checkPostcode={this.checkPostcode}
    //  userData={userData}
    ///>
  //</View>
  //: null}
  renderAddressModal = (isVisible, toggleAddressModal, cartData, userData) => {
    return (
      <Modal
        animationIn={global.windowWidth > 768 ? "fadeIn" : "slideInUp"}
        animationOut={global.windowWidth > 768 ? "fadeOut" : "slideOutDown"}
        isVisible={this.props.isVisible}
        style={global.windowWidth > 768 ? styles.containerlWeb : styles.container}
        deviceHeight={Dimensions.get('window').height}
        deviceWidth={Dimensions.get('window').width}
      >

      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
      >

        <View style={[global.windowWidth > 768 ? styles.modalWeb : styles.modal]}>
            <View style={styles.header}>
              <Text style={styles.heading} adjustsFontSizeToFit allowFontScaling>Delivery Address</Text>
                <TouchableOpacity
                  onPress={() => toggleAddressModal()}
                  style={styles.headerClose}>
                  <Icon name="x" size={25} color={colors.textSecondary}/>
              </TouchableOpacity>
            </View>

          <ScrollView>

          <Text style={{fontSize: 14, fontWeight: "600", marginTop: 30, marginBottom: 10}} adjustsFontSizeToFit allowFontScaling>Postcode</Text>

          <View style={styles.findPostcode}>
            <TextInput
              placeholder="Enter your postcode here"
              style={styles.findPostcodeInput}
              value={this.state.postcode}
              autoCapitalize="characters"
              onChangeText={(postcode) => (this.setState({ postcode }), this.resetError())}
            />
            <TouchableOpacity
              onPress={() => this.findPostcode()}
              style={styles.findPostcodeButton}
            >
              {this.state.addressLoading ?
                <ActivityIndicator size="small" color={colors.white} />
              :  <Text style={{color: colors.text, textAlign: "center", paddingTop: 2, fontFamily: fonts.bold }} adjustsFontSizeToFit allowFontScaling>Find</Text> }
            </TouchableOpacity>
          </View>

          <Text style={{ marginTop: 5, marginBottom: 10, color: colors.red, fontFamily: fonts.bold}} adjustsFontSizeToFit allowFontScaling>{this.state.errorMessage}</Text>

          <CustomerSelectAddress
            addresses={this.state.foundAddresses}
            selectedAddress={this.state.selectedAddress}
            selectAddress={this.selectAddress}
            addressLoading={this.state.addressLoading}
            isVisible={this.state.isFindAddressVisible}
          />

          {this.state.selectedAddress != null && this.state.addressLoading != true || this.state.line1 != null ?
            <View>
              <Text style={{fontSize: 14, fontFamily: fonts.regular, marginTop: 0, marginBottom: 10}} adjustsFontSizeToFit allowFontScaling>Line 1</Text>
              <TextInput
                placeholder="First line of address"
                style={styles.findPostcodeInput}
                value={this.state.line1}
                autoCapitalize="characters"
                onChangeText={(line1) => (this.setState({ line1 }), this.resetError())}
              />

              <Text style={{fontSize: 14, fontFamily: fonts.regular, marginTop: 20, marginBottom: 10}} adjustsFontSizeToFit allowFontScaling>Line 2</Text>
              <TextInput
                placeholder="Second line of address"
                style={styles.findPostcodeInput}
                value={this.state.line2}
                autoCapitalize="characters"
                onChangeText={(line2) => (this.setState({ line2 }), this.resetError())}
              />

              <Text style={{fontSize: 14, fontFamily: fonts.regular, marginTop: 20, marginBottom: 10}} adjustsFontSizeToFit allowFontScaling>County</Text>
              <TextInput
                placeholder="County of address"
                style={styles.findPostcodeInput}
                value={this.state.county}
                autoCapitalize="characters"
                onChangeText={(county) => (this.setState({ county }), this.resetError())}
              />

              <Text style={{fontSize: 14, fontFamily: fonts.regular, marginTop: 20, marginBottom: 10}} adjustsFontSizeToFit allowFontScaling>Town</Text>
              <TextInput
                placeholder="Town"
                style={styles.findPostcodeInput}
                value={this.state.town}
                autoCapitalize="characters"
                onChangeText={(town) => (this.setState({ town }), this.resetError())}
              />
            </View>
          : null}

          <AddAddressButton
            line1={this.state.line1}
            line2={this.state.line2}
            town={this.state.town}
            county={this.state.county}
            postcode={this.state.postcode}
            location={this.state.location}
            selectedAddress={this.state.selectedAddress}
            toggleAddressModal={toggleAddressModal}
          />

        </ScrollView>
        </View>
        </KeyboardAvoidingView>
      </Modal>
    )
  }

  render() {
    return (
      <View>{this.renderAddressModal(this.props.isVisible, this.props.toggleAddressModal, this.props.cartData, this.props.userData, this.props.deliveriesData)}</View>
    )
  }
}

export default AddressModal;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 0,
    justifyContent: "flex-end"
  },
  containerWeb: {
    flex: 1,
    margin: 0,
    alignContent: "center",
  },
  text: {
    color: colors.text,
    fontFamily: fonts.regular
  },
  modal: {
    minHeight: 400,
    backgroundColor:colors.white,
    width: Dimensions.get('window').width,
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    padding: 30,
  },
  modalWeb: {
    minHeight: 300,
    backgroundColor:colors.white,
    minWidth: 600,
    alignSelf: "center",
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
    padding: 30,
  },
  header: {
    flexDirection: "row",
    marginBottom: 10,
  },
  headerClose: {
    flex: 0.5,
    alignItems: "flex-end",
  },
  heading: {
    flex: 4,
    color: colors.text,
    fontSize: 24,
    fontFamily: fonts.bold
  },
  orderButton: {
    height: 58,
    backgroundColor: colors.secondary,
    paddingVertical: 20,
    paddingHorizontal: 30,
    borderRadius: 40,
  },
  buttonText: {
    fontFamily: fonts.bold,
    fontSize: 15,
    marginTop: -2,
    textAlign: "center",
    color: colors.primary
  },
  orderButtonDisabled: {
    backgroundColor: colors.secondary,
    opacity: 0.8,
    paddingVertical: 20,
    paddingHorizontal: 30,
    borderRadius: 40,
    marginTop: 20,
  },
  items: {
    flex: 5,
    paddingVertical: 20,
  },
  receiptDetails: {
    flex: 0.5,
    borderTopWidth: 1,
    borderTopColor: colors.border
  },
  savedAddress: {
    flexDirection: "row",
    paddingVertical: 15,
    borderBottomWidth: 1,
    borderBottomColor: colors.border,
    borderTopWidth: 1,
    borderTopColor: colors.border
  },
  savedAddressIcon: {
    marginTop: 5,
    marginRight: 15,
  },
  savedAddressOpen: {
    flex: 0.5,
    marginTop: 5,
    marginRight: 15,
    alignSelf: "flex-end"
  },
  savedAddressText: {
    flex: 4,
    fontFamily: fonts.regular
  },
  receiptDetails: {
    flex: 1.5,
  },
  subHeading: {
    fontSize: 12,
    letterSpacing: 2,
    marginVertical: 10,
    textTransform: "uppercase",
    fontWeight: "700"
  },
  findPostcode: {
    flexDirection: "row"
  },
  findPostcodeInput: {
    flex: 3,
    paddingVertical: 30,
    paddingHorizontal: 20,
    borderWidth: 1,
    fontFamily: fonts.regular,
    borderColor: colors.border
  },
  findPostcodeButton: {
    flex: 1.5,
    paddingVertical: 20,
    paddingHorizontal: 20,
    backgroundColor: colors.primary,
  }
});
