import * as React from 'react';
import { View, ScrollView, StyleSheet, ActivityIndicator, Text, Button, TouchableOpacity, TextInput, KeyboardAvoidingView, Platform } from 'react-native';

import BouncyCheckbox from "react-native-bouncy-checkbox";

import * as firebase from 'firebase';

import { colors } from './../constants/colors';
import { fonts } from './../constants/fonts'

class RegisterScreen extends React.Component {

  state = {
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    secondName: "",
    phone: "",
    errorMessage: null,
    formLoading: false,
    termsIsChecked: false,
    marketingIsChecked: false,
  }

  resetError = () => {
    return this.setState({errorMessage: null})
  }


  setTermsChecked = () => {

    this.resetError()
    if (this.state.termsIsChecked == true) {
      this.setState({termsIsChecked: false})
    } else {
        this.setState({termsIsChecked: true})
    }
  }

  setMarketingChecked = () => {

    this.resetError()
    if (this.state.marketingIsChecked == true) {
      this.setState({marketingIsChecked: false})
    } else {
        this.setState({marketingIsChecked: true})
    }
  }

  handleRegister = () => {

    this.setState({ formLoading: true })

    const emailReg = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    const phoneReg = new RegExp(/^((\+44)|(0)) ?\d{4} ?\d{6}$/)

    if (this.state.email == "" ||
        this.state.password == "" ||
        this.state.confirmPassword == "" ||
        this.state.firstName == "" ||
        this.state.secondName == "" ||
        this.state.phone == "") {
          this.setState({errorMessage: "Please fill out the required fields", formLoading: false})
    } else {


      if (this.state.password != this.state.confirmPassword) {
        this.setState({errorMessage: "Passwords don't match", formLoading: false})
      }

      else if (emailReg.test(this.state.email) === false) {
        this.setState({errorMessage: "Please enter a valid email address", formLoading: false})
      } else if (phoneReg.test(this.state.phone) === false) {
        this.setState({errorMessage: "Please enter a valid phone number", formLoading: false})
      }  else if (this.state.termsIsChecked === false) {
        this.setState({errorMessage: "You must agree to the Terms & Conditions", formLoading: false})
      } else {
        // Created Auth User Within Business own Database.
        firebase
          .auth()
          .createUserWithEmailAndPassword(this.state.email, this.state.password)
          .then(userCredentials => {

            // Addds user to Company Customers
            // Get Timestamp
            const timeCreated = firebase.firestore.FieldValue.serverTimestamp();

            const customerData = {
              firstName: this.state.firstName,
              secondName: this.state.secondName,
              email: this.state.email,
              phone: this.state.phone,
              timeCreated: timeCreated,
              uid: userCredentials.user.uid,
              allowMarketing: this.state.marketingIsChecked,
              address: {
                line1: null,
                line2: null,
                town: null,
                county: null,
                postcode: null,
              }
            }

            global.backendFirebase.firestore()
              .collection("/customers/" + global.companyId + "/customer")
                .add(customerData)
                .then(() => {
                  this.props.navigation.navigate('Order Takeaway');
                })
                .catch((error) => {
                  this.setState({errorMessage: "Problem adding user", formLoading: false})
                })
            })
        .catch(error => this.setState({errorMessage: error.message, formLoading: false}))
      }
    }
  }
  render() {
    return (
      <View style={styles.main}>
        <View style={styles.header}>
          <View style={styles.container}>
          <Text style={{color: colors.secondary, fontFamily: fonts.bold, fontSize: 24}}>Register Account</Text>
          </View>
        </View>

        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : "height"}
          keyboardVerticalOffset={120}
          style={{
          flex: 1,
        }}
        >

        <ScrollView style={[styles.container, { paddingVertical: 30}]}>

          {this.state.errorMessage ?
            <Text style={styles.errorMessage}><Text style={{ fontFamily: fonts.bold }}>Error</Text> {this.state.errorMessage}</Text>
          : null}

          <Text style={styles.heading}>First Name<Text style={styles.inputRequired}> *</Text></Text>
          <TextInput
            style={styles.input}
            autoCapitalize="words"
            autoCompleteType="givenName"
            onChangeText={firstName => (this.setState({ firstName }), this.resetError())}
            value={this.state.firstName}
            ref={(input) => { this.input1 = input; }}
            onSubmitEditing={() => { this.input2.focus(); }}
          ></TextInput>


          <Text style={styles.heading}>Last Name<Text style={styles.inputRequired}> *</Text></Text>
          <TextInput
            style={styles.input}
            autoCapitalize="words"
            autoCompleteType="familyName"
            onChangeText={secondName => (this.setState({ secondName }), this.resetError())}
            value={this.state.secondName}
            ref={(input) => { this.input2 = input; }}
            onSubmitEditing={() => { this.input3.focus(); }}
          ></TextInput>

          <Text style={styles.heading}>Email<Text style={styles.inputRequired}> *</Text></Text>
          <TextInput
            style={styles.input}
            autoCapitalize="none"
            autoCompleteType="email"
            onChangeText={email => (this.setState({ email }), this.resetError())}
            value={this.state.email}
            ref={(input) => { this.input3 = input; }}
            onSubmitEditing={() => { this.input4.focus(); }}
          ></TextInput>

          <Text style={styles.heading}>Mobile Number<Text style={styles.inputRequired}> *</Text></Text>
          <TextInput
            style={styles.input}
            autoCapitalize="none"
            autoCompleteType="telephoneNumber"
            onChangeText={phone => (this.setState({ phone }), this.resetError())}
            value={this.state.phone}
            keyboardType="phone-pad"
            ref={(input) => { this.input4 = input; }}
            onSubmitEditing={() => { this.input5.focus(); }}
          ></TextInput>

          <Text style={styles.heading}>Password<Text style={styles.inputRequired}> *</Text></Text>
          <TextInput
            style={styles.input}
            autoCapitalize="none"
            secureTextEntry
            autoCompleteType="password"
            onChangeText={password => (this.setState({ password }), this.resetError())}
            value={this.state.password}
            ref={(input) => { this.input5 = input; }}
            onSubmitEditing={() => { this.input6.focus(); }}
            ></TextInput>

            <Text style={styles.heading}>Confirm Password<Text style={styles.inputRequired}> *</Text></Text>
            <TextInput
              style={styles.input}
              autoCapitalize="none"
              secureTextEntry
              autoCompleteType="password"
              onChangeText={confirmPassword => (this.setState({ confirmPassword }), this.resetError())}
              value={this.state.confirmPassword}
              ref={(input) => { this.input6 = input; }}
              ></TextInput>

              <View style={styles.checkboxSection}>

                <BouncyCheckbox
                  size={22}
                  isChecked={this.state.termsIsChecked}
                  fillColor={colors.text}
                  unfillColor="f0f0f0"
                  disableBuiltInState={true}
                  iconStyle={{ borderColor: colors.textSecondary }}
                  onPress={() => this.setTermsChecked()}
                />
                <View style={styles.checkboxLabel}>
                  <Text style={styles.text}>I agree to the</Text>
                    <TouchableOpacity
                              onPress={() => this.props.navigation.navigate("Terms")}
                              >
                              <Text style={{ fontFamily: fonts.regular, color: colors.textSecondary}}> Terms and Conditions.</Text>
                              </TouchableOpacity>
                </View>
              </View>

              <View style={styles.checkboxSection}>

                <BouncyCheckbox
                  size={22}
                  isChecked={this.state.marketingIsChecked}
                  fillColor={colors.text}
                  unfillColor="f0f0f0"
                  disableBuiltInState={true}
                  iconStyle={{ borderColor: colors.textSecondary }}
                  onPress={() => this.setMarketingChecked()}
                />
                <View style={styles.checkboxLabel}>
                  <Text style={styles.text}>I wish to recieve emails about offers and news.</Text>
                </View>
              </View>

            <TouchableOpacity style={styles.button} onPress={this.handleRegister}>
              {this.state.formLoading ?
              <ActivityIndicator size="small" color={colors.text} />
              : <Text style={styles.buttonText}>Register Account</Text> }
            </TouchableOpacity>

            <View style={{ flexDirection: "row", justifyContent: "center",     marginBottom: 30,}}>
              <Text style={styles.footerText}>Already got an account?</Text>
              <TouchableOpacity
              style={{ paddingTop: 20}}
              onPress={() => this.props.navigation.navigate("Login")}
              >
              <Text style={styles.footerText, { fontWeight: fonts.regular, color: colors.textSecondary, textAlign: "center"}}> Log in.</Text>
              </TouchableOpacity>
            </View>
        </ScrollView>
            </KeyboardAvoidingView>


      </View>
    )
  }
}

export default RegisterScreen;

const styles = StyleSheet.create({
  main: {
    flex: 1,
    backgroundColor: colors.white
  },
  container: {
    flex: 1,
    alignSelf: 'center',
    width: "100%",
    maxWidth: 500,
    paddingHorizontal: 30,
  },
  text: {
    color: colors.text,
    fontFamily: fonts.regular
  },
  header: {
    height: 90,
    paddingTop: 20,
    backgroundColor: colors.primary,
  },
  heading: {
    fontSize: 10,
    letterSpacing: 0.5,
    color: colors.text,
    fontFamily: fonts.bold,
    textTransform: "uppercase"
  },
  button: {
    height: 58,
    backgroundColor: colors.secondary,
    paddingVertical: 20,
    paddingHorizontal: 30,
    borderRadius: 40,
    marginTop: 20,
  },
  buttonText: {
    fontWeight: "700",
    fontSize: 16,
    textAlign: "center",
    color: colors.primary,
    fontFamily: fonts.bold
  },
  input: {
    fontSize: 15,
    paddingTop: 10,
    paddingBottom: 10,
    marginBottom: 25,
    borderBottomColor: colors.border,
    borderBottomWidth: 1,
    fontFamily: fonts.regular
  },
  inputRequired: {
    color: colors.red,
    fontFamily: fonts.bold
  },
  errorMessage: {
    color: colors.white,
    padding: 10,
    backgroundColor: colors.red,
    marginBottom: 30,
    fontFamily: fonts.regular
  },
  checkboxSection: {
    marginVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkboxLabel: {
    marginLeft: -5,
    marginTop: -2,
    flexDirection: "row",
    fontFamily: fonts.regular
  },
  checkbox: {
    margin: 8,
  },
  footerText: {
    paddingTop: 20,
    color: colors.text,
    fontFamily: fonts.regular
  }
});
